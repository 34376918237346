import React, { useEffect, useState } from "react"
import style from "./countDownTimer.module.css"

interface CountDownTimerProps {
    title: string
    eventDate: Date
    daysStr: string
    hoursStr: string
    minutesStr: string
    secondsStr: string
    status?: string
    callback?: any
}

export function CountDownTimer(props: CountDownTimerProps) {

    const [timerDays, setTimerDays] = useState(0)
    const [timerHours, setTimerHours] = useState(0)
    const [timerMinutes, setTimerMinutes] = useState(0)
    const [timerSeconds, setTimerSeconds] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            const countdownDate = props.eventDate.getTime();
            const now = new Date().getTime();
            const distance = countdownDate - now;

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (distance < 0) {
                setTimeout(() => {
                    if (typeof props.callback === "function") props.callback();
                }, 1000);
                clearInterval(interval);
            } else {
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
            }
        }, 1000)

        return () => {
            clearInterval(interval);
        }
    }, [props.eventDate])

    const format = (value) => {
        const formattedValue = String(value);
        if (formattedValue.length < 2) return "0" + formattedValue;
        else return formattedValue;
    }

    const renderDay = timerDays > 0 ? <div className={style.countDownText}>
        <p className={style.bigNumber}>{format(timerDays)}</p><p>{props.daysStr}</p>
    </div> : null;
    const renderHour = timerDays > 0 || timerHours > 0 ? <div className={style.countDownText}>
        <p className={style.bigNumber}>{format(timerHours)}</p><p>{props.hoursStr}</p>
    </div> : null;
    const renderMinute = timerDays > 0 || timerHours > 0 || timerMinutes > 0 ? <div className={style.countDownText}>
        <p className={style.bigNumber}>{format(timerMinutes)}</p><p>{props.minutesStr}</p>
    </div> : null;
    const renderSecond = <div className={style.countDownText}>
        <p className={style.bigNumber}>{format(timerSeconds)}</p><p>{props.secondsStr}</p>
    </div>

    const lifePageClass = (props.status === "current" || props.status === "last" || props.status === "hidden") ? style.invisible : style.lifePage;

    const countDownRunning = timerDays > 0 || timerHours > 0 || timerMinutes > 0 || timerSeconds > 0;
    const renderTimer = props.status || countDownRunning

    if (!renderTimer) {
        return (<></>)
    }

    if (props.status) {
        return (
            <div className={lifePageClass}>
                <div className={style.content}>
                    <div className={style.countDownTitle}>{props.title}</div>
                    <div className={style.countDownTimer}>
                        {renderDay}
                        {renderHour}
                        {renderMinute}
                        {renderSecond}
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className={style.countDown}>
                <div className={style.countDownTitle}>{props.title}</div>
                <div className={style.countDownTimer}>
                    {renderDay}
                    {renderHour}
                    {renderMinute}
                    {renderSecond}
                </div>
            </div>
        )
    }
}