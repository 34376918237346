import { navigate } from "gatsby"
import parse from "html-react-parser"
import React, { useLayoutEffect } from "react"
import { useLoginState } from "../api/login/loginState"
import "../App.css"
import {
  ButtonBuehler,
  CookieBanner,
  Footer,
  Header,
  Loading,
  SegmentColumn,
} from "../components/"
import { BulletPoint } from "../components/atoms/bulletPoint"
import { CountDownTimer } from "../components/molecules/countDownTimer"
import { Drop } from "../components/organisms/drop"
import ChatIcon from "../icons/LandingPage/icon-32_match-making.svg"
import KeyVisual from "../images/Hero-Background.jpg"
import { customEvent } from "../utility/analyticx"
import { useCmsData } from "../utility/CmsDataProvider"
import { getCmsLanguage, getLanguage } from "../utility/language"
import style from "./style.module.css"

export default function Home() {
  const { data, loading, error } = useCmsData()
  const {
    state: { loggedIn },
    dispatch,
  } = useLoginState()

  if (loading) {
    return <Loading></Loading>
  } else if (error) {
    return <h2>Error while loading CMS...</h2>
  }

  useLayoutEffect(() => {
    try {
      $crisp.push(["do", "chat:hide"])
    } catch (e) {}
  }, [])

  const region = getLanguage()

  //Set language for every part of the used Model
  data?.setLanguage(getCmsLanguage())
  data?.asModel
    .reffromreflist("languagefile", "4wjddxbv5wnj5l6tbbjqjkrop7gbpvcfvpx2q")
    ?.setLanguage(getCmsLanguage())
  data?.asModel.reflist("keytopics").map(keyTopic => {
    keyTopic.setLanguage(getCmsLanguage())
  })
  data?.asModel.reflist("columns").map(column => {
    column.setLanguage(getCmsLanguage())
  })

  const introTitle = data?.asModel.str("introTitle")
  const introDescription = data?.asModel.str("introDescription")
  const landingPageRefList = data?.asModel
    .reffromreflist("languagefile", "4wjddxbv5wnj5l6tbbjqjkrop7gbpvcfvpx2q")
    ?.asModel.objlist("entries")
  const requestMeeting =
    landingPageRefList.find(i => i.Key === "index.appointment") || ""
  const joinNow =
    landingPageRefList.find(i => i.Key === "index.overlay.appointment") || ""
  const registerNow =
    landingPageRefList.find(i => i.Key === "index.register") || ""
  const days = landingPageRefList.find(i => i.Key === "countdown.days") || ""
  const hours = landingPageRefList.find(i => i.Key === "countdown.hours") || ""
  const minutes =
    landingPageRefList.find(i => i.Key === "countdown.minutes") || ""
  const seconds =
    landingPageRefList.find(i => i.Key === "countdown.seconds") || ""

  const introImage = data?.asModel.ref("introImage").asAsset.url
  const countdownTitle = data?.asModel.str("countdownTitle")
  const countdownEnd = data?.asModel.obj("countdownDate").Timestamp
  const keyTopicTitle = data?.asModel.str("keytopicsTitle")
  const keyTopics = data?.asModel.reflist("keytopics") || []
  const moreColumns = data?.asModel.reflist("columns")

  const keyTopicBlock: Array<JSX.Element> = []
  for (let i = 0; i < keyTopics?.length; i = i + 3) {
    if (keyTopics?.length > i + 2) {
      keyTopicBlock.push(
        <div className={style.topicsItems} key={i}>
          <BulletPoint>{keyTopics[i].asModel.str("name")}</BulletPoint>
          <BulletPoint>{keyTopics[i + 1].asModel.str("name")}</BulletPoint>
          <BulletPoint>{keyTopics[i + 2].asModel.str("name")}</BulletPoint>
        </div>
      )
    } else if (keyTopics?.length > i + 1) {
      keyTopicBlock.push(
        <div className={style.topicsItems} key={i}>
          <BulletPoint>{keyTopics[i].asModel.str("name")}</BulletPoint>
          <BulletPoint>{keyTopics[i + 1].asModel.str("name")}</BulletPoint>
        </div>
      )
    } else {
      keyTopicBlock.push(
        <div className={style.topicsItems} key={i}>
          <BulletPoint>{keyTopics[i].asModel.str("name")}</BulletPoint>
        </div>
      )
    }
  }

  return (
    <div>
      <Header />
      <title>Bühler Virtual World</title>
      <CookieBanner />
      <div className={style.keyVisual}>
        <div className={style.keyVisualHero}></div>
      </div>
      {/*  <img src={KeyVisual} className={style.imgKeyVisual} />

      <div className={style.container}>
        <Drop isLoggedIn={loggedIn} />
      </div> */}

      <div id="firstSegment" className={style.firstSegment}>
        <img
          src={introImage}
          className={`${style.space} " " ${style.firstSegmentImage}`}
        />
        <div className={style.firstSegmentText}>
          <h2 className={`${style.firstSegmentHeading} " " ${style.space}`}>
            {introTitle}
          </h2>
          <p className={style.space}>{parse(introDescription || "")}</p>
          <br></br>
          <div className={style.space}>
            <ButtonBuehler
              green={true}
              onClick={() => {
                window.open(
                  `https://www.buhlergroup.com/content/buhlergroup/global/${
                    region === "de_DE" ? "de" : "en"
                  }/contact.html`
                )
              }}
            >
              {" "}
              {registerNow.Value}
            </ButtonBuehler>
          </div>
        </div>
      </div>

      <CountDownTimer
        eventDate={new Date(`${countdownEnd}`)}
        daysStr={days.Value}
        hoursStr={hours.Value}
        minutesStr={minutes.Value}
        secondsStr={seconds.Value}
        title={countdownTitle}
      />

      <div className={style.footerLandingPage}>
        <Footer />
      </div>
    </div>
  )
}
