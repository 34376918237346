import React from "react"
import BulletPointImage from "../../../icons/LandingPage/filled-dot-small_new.svg"
import style from "./bulletPoint.module.css"

interface BulletPointProps {
    children?: React.ReactNode
}

export function BulletPoint(props: BulletPointProps) {
    return (
        <div className={style.bulletPointContainer}>
            <img src={BulletPointImage} className={style.bulletPointImg}/>
            <p className={style.bulletPointText}>{props.children}</p>
        </div>
    )
}